import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { router } from './router'
import store from './store/index'
import interceptors from './api/interceptors'
import VueDebounce from 'vue-debounce'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueDebounce)
interceptors(store)

const app = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

store.$app = app

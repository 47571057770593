import axi from './axi'
import TokenService from './token-service'

class AuthService {
  login (user) {
    return axi
      .post('/user/login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (!response.data.token) {
          throw new Error('Invalid answer received from backend')
        }
        TokenService.setUser(response.data)

        return response.data
      })
  }

  logout () {
    TokenService.unsetUser()
  }
}

export default new AuthService()

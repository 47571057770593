<template>
  <div class="container"
       id="app"
  >
    <b-navbar
        v-if="loggedIn"
        toggleable="lg"
        type="dark"
        variant="dark"
        class="mb-3"
    >
      <b-navbar-brand :to="{ name: 'Home' }">
        PriceCrab
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse
          id="nav-collapse"
          is-nav
      >
        <b-navbar-nav>
          <b-nav-item
              v-if="isAdmin"
              :to="{ name: 'Balances' }"
          >
            <b-icon-coin />
          </b-nav-item>
          <b-nav-item
              v-else
              :to="{ name: 'Balance' }"
          >
            <b-icon-coin />
          </b-nav-item>
          <b-nav-item
              v-if="isAdmin"
              :to="{ name: 'Users' }"
          >
            <b-icon-person-bounding-box />
          </b-nav-item>
          <b-nav-item
              v-if="isAdmin"
              :to="{ name: 'Config' }"
          >
            <b-icon-gear />
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item
              @click="logout()"
          >
            <b-icon-box-arrow-right />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container fluid>
      <router-view />
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAdmin () {
      return this.$store.getters.isAdmin
    },
    loggedIn () {
      return this.$store.getters.loggedIn
    }
  },
  methods: {
    logout () {
      this.$router.push({ name: 'Login' })
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}

table.table thead th {
  vertical-align: middle;
}
</style>

import axi from './axi'
import TokenService from './token-service'

const interceptors = (store) => {
  axi.interceptors.request.use(
    (config) => {
      const token = TokenService.localAccessToken()
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axi.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url === '/user/login' || !err.response) {
        return Promise.reject(err)
      }

      if (err.response.status !== 401) {
        return Promise.reject(err)
      }

      if (originalConfig._retry) {
        store.dispatch('auth/logout')
        await store.$app.$router.push({ name: 'Login' })
        return Promise.reject(new Error('Сессия просрочена. Необходимо заново войти в систему'))
      }

      const rs = await axi.post('/user/token/refresh', TokenService.localRefreshData(), { _retry: true })

      store.dispatch('auth/refreshToken', rs.data)
      TokenService.setUser(rs.data)
      return axi(originalConfig)
    }
  )
}

export default interceptors

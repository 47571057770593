import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

export const router = new VueRouter({
  mode: 'history',
  exact: true,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "user-login" */ '../views/user/Login.vue')
    },
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "user-home" */ '../views/user/Home.vue'),
      meta: {
        auth: {
          required: true
        }
      }
    },
    {
      path: '/users',
      name: 'Users',
      component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/Users.vue'),
      meta: {
        auth: {
          required: true,
          roles: 'Admin'
        }
      }
    },
    {
      path: '/config',
      name: 'Config',
      component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/Config.vue'),
      meta: {
        auth: {
          required: true,
          roles: 'Admin'
        }
      }
    },
    {
      path: '/balances',
      name: 'Balances',
      component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/Balance.vue'),
      meta: {
        auth: {
          required: true,
          roles: 'Admin'
        }
      }
    },
    {
      path: '/balance',
      name: 'Balance',
      component: () => import(/* webpackChunkName: "admin-users" */ '../views/user/Balance.vue'),
      meta: {
        auth: {
          required: true
        }
      }
    }
  ]
})

Vue.use(VueRouter)

router.beforeEach((to, from, next) => {
  if (!to.meta?.auth?.required) {
    next()
    return
  }
  if (!store.getters.loggedIn) {
    next({
      name: 'Login',
      replace: true
    })
    return
  }
  if (!to.meta.auth.roles) {
    next()
    return
  }
  if (to.meta.auth.roles.includes(store.state.auth.user.role)) {
    next()
    return
  }
  next({
    name: 'Home',
    replace: true
  })
})

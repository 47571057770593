import Vuex from 'vuex'
import Vue from 'vue'
import { auth } from './auth-module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  getters: {
    loggedIn (state) {
      return state.auth.status.loggedIn
    },
    isAdmin (state) {
      if (!state.auth.user) {
        return false
      }
      return state.auth.user.role === 'Admin'
    }
  }
})

class TokenService {
  localRefreshData () {
    const user = JSON.parse(localStorage.getItem('user'))
    return { 'uuid': user?.uuid, refreshToken: user?.refreshToken }
  }

  localAccessToken () {
    const user = JSON.parse(localStorage.getItem('user'))
    return user?.token
  }

  user () {
    return JSON.parse(localStorage.getItem('user'))
  }

  setUser (user) {
    if (!user) {
      return
    }
    localStorage.setItem('user', JSON.stringify(user))
  }

  unsetUser () {
    localStorage.removeItem('user')
  }
}

export default new TokenService()
